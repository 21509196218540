<template>
    <b-modal
        v-model="show"
        size="lg"
        centered
        hide-footer
        title="Prepare Your Dry Run"
        header-bg-variant="warning"
        @change="emitInput"
        scrollable
    >
        <div role="tablist" class="tabbed-form">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1 form-step-name" role="tab">
                    <img src="../../assets/gcp-icons/step.png" />
                    Dry Run Settings
                </b-card-header>
                <b-collapse visible role="tabpanel">
                    <b-card-body>
                        <b-form class="step-form">
                            <b-form-group
                                label="Project"
                                label-for="dry-run-project"
                                :label-cols="2"
                                :horizontal="true"
                            >
                                <b-row>
                                    <b-col md="8">
                                        <wit-select
                                            label="name"
                                            placeholder="Select a project"
                                            v-model="$v.dryRunForm.project.$model"
                                            :options="projects"
                                        ></wit-select>
                                        <Feedback
                                            :state="validateRef('dryRunForm.project')"
                                            invalid="This field is required"
                                            valid="Project is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <b-form-group label-cols-lg="2" label="Date">
                                <b-row>
                                    <b-col md="8">
                                        <Datetime
                                            format="yyyy-MM-dd"
                                            placeholder="Date"
                                            input-class="form-control"
                                            v-model="$v.dryRunForm.date.$model"
                                            @input="onDateChange"
                                        ></Datetime>
                                        <Feedback
                                            :state="validateRef('dryRunForm.date')"
                                            invalid="This field is required"
                                            valid="Date is valid"
                                        ></Feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <answer-builder
                                v-if="dryRunForm.project"
                                ref="answerBuilder"
                                v-model="$v.dryRunForm.answers.$model"
                                :questions="questions"
                                :valid.sync="answersValid"
                                :project="dryRunForm.project"
                                :relay-custom-queries="versions"
                            />

                            <b-row>
                                <b-col>
                                    <b-button :disabled="$v.dryRunForm.$invalid" @click="testQuery" variant="primary">
                                        <i class="icon-check"></i> Test
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card-body>

                    <b-card v-if="selectedResources.length" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1 form-step-name" role="tab">
                            <p
                                :class="{
                                    'm-1': true,
                                    'collapse-section-button': true,
                                    'arrow-right': variablesUncollapsed,
                                    'arrow-down': !variablesUncollapsed,
                                }"
                                @click="variablesUncollapsed = !variablesUncollapsed"
                            >
                                Variables
                            </p>
                        </b-card-header>
                        <b-collapse v-model="variablesUncollapsed" role="tabpanel">
                            <b-card-body>
                                <b-form class="step-form">
                                    <b-form-group
                                        :label="variable.key"
                                        :label-cols="2"
                                        :horizontal="true"
                                        v-for="(variable, idx) in selectedResources"
                                        :key="idx"
                                    >
                                        <b-row>
                                            <b-col md="12">
                                                <json-viewer
                                                    :value="variable.value"
                                                    :expand-depth="5"
                                                    copyable
                                                    boxed
                                                    sort
                                                    @copied="() => copyToClipboard(JSON.stringify(variable.value))"
                                                ></json-viewer>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-form>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card v-if="dryRunResponse && dryRunResponse.statistics" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1 form-step-name" role="tab"
                            >Estimations</b-card-header
                        >
                        <b-collapse visible role="tabpanel">
                            <b-card-body>
                                <b-form class="step-form">
                                    <p>
                                        Monthly cost (run every hour) :
                                        {{ calculateEstimatedCost(dryRunResponse.statistics.totalBytesProcessed) }}
                                        $
                                    </p>
                                    <p>
                                        Monthly processed (run every hour) :
                                        {{
                                            calculateEstimatedBytesProcessed(
                                                dryRunResponse.statistics.totalBytesProcessed
                                            )
                                        }}
                                        GB
                                    </p>
                                </b-form>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <json-viewer
                        v-if="dryRunResponse"
                        :value="dryRunResponse"
                        :expand-depth="5"
                        copyable
                        boxed
                        sort
                        @copied="() => copyToClipboard(JSON.stringify(dryRunResponse))"
                    ></json-viewer>
                </b-collapse>
            </b-card>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </b-modal>
</template>

<script>
import {Datetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import JsonViewer from 'vue-json-viewer'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import AnswerBuilder from '../Reports/DataProviders/AnswerBuilder/AnswerBuilder.vue'

export default {
    components: {
        AnswerBuilder,
        Datetime,
        Feedback,
        JsonViewer,
        Loading,
    },
    props: {
        value: {
            required: true,
        },
        query: {
            type: String,
            required: true,
        },
        questions: {
            type: Array,
            default: () => [],
        },
        variables: {
            type: Array,
            default: () => [],
        },
        versions: {
            type: Array,
            default: null,
        },
    },
    mixins: [validationMixin],
    validations: {
        dryRunForm: {
            project: {required},
            answers: {
                valid: function () {
                    return this.answersValid
                },
            },
            date: {
                required,
                valid: (value) => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value),
            },
        },
    },
    data() {
        return {
            show: false,
            dryRunResponse: null,
            dryRunForm: {
                project: null,
                answers: [],
                date: '',
            },
            answersValid: false,
            variablesUncollapsed: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            collect: 'collect/all',
            loading: 'loading/state',
            process: 'process/all',
            projects: 'project/projects',
            report: 'report/all',
        }),
        resourceOptions() {
            return [
                ...this.collect.map((el) => ({resource: 'collect', ...el})),
                ...this.process.map((el) => ({resource: 'process', ...el})),
                ...this.report.map((el) => ({resource: 'report', ...el})),
            ]
        },
        selectedResources() {
            return this.dryRunForm.answers
                .map((el) => this.getVariableResources(el))
                .flat()
                .filter(Boolean)
        },
    },
    created() {
        this.show = this.value
    },
    watch: {
        value() {
            if (this.value) {
                this.dryRunForm.answers = []
            }
            this.show = this.value
        },
    },
    methods: {
        emitInput() {
            this.$emit('input', this.show)
        },

        calculateEstimatedCost(bytesProcessed) {
            return ((bytesProcessed / (1024 * 1024 * 1024 * 1024)) * 24 * 30 * 5).toFixed(2)
        },

        calculateEstimatedBytesProcessed(bytesProcessed) {
            return ((bytesProcessed / (1024 * 1024 * 1024)) * 24 * 30).toFixed(2)
        },

        onDateChange(value) {
            if (value === '') {
                this.$v.dryRunForm.date.$reset()
            }
        },

        testQuery() {
            this.$store.commit('loading/PROCESSING', `Testing Your Query...`)

            const data = {
                query: this.query,
                variables: this.variables,
                projectId: this.dryRunForm.project.id,
                answers: this.dryRunForm.answers,
                date: this.dryRunForm.date,
            }

            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/dryRun`, data)
                .then((response) => {
                    const {
                        data: {data},
                    } = response

                    this.dryRunResponse = data
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((error) => {
                    this.$store.commit('loading/PROCESSED')
                })
        },

        getVariableResources(variable) {
            let variables = []

            if (variable.variables) {
                variables = variable.variables.map((_variable) => this.getVariableResources(_variable)).flat()
            }

            if (variable.value && variable.value.id) {
                return [
                    {
                        key: variable.key,
                        value: this.resourceOptions.find(
                            (option) => option.id === variable.value.id && option.resource === variable.value.type
                        ),
                    },
                    ...variables,
                ]
            }

            if (variable.values && variable.values.some((value) => value.id)) {
                return [
                    {
                        key: variable.key,
                        value: variable.values.map((value) =>
                            this.resourceOptions.find(
                                (option) => option.id === value.id && option.resource === value.type
                            )
                        ),
                    },
                    ...variables,
                ]
            }

            return variables
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
        },

        validateRef(ref, step) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            // if (step.checked) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style></style>
